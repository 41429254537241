



















































































// Core
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Debounce from '@/decorators/Debounce'

// Store
import { ProjectsActions } from '@store/projects/types'

// Interfaces
import { ISelect } from '@/interfaces/Interface'
import { Meta, RequestParams } from '@store/interfaces'
import { ProjectsState, Project } from '@store/projects/interfaces'

const NSProjects = namespace('projectsModule')

@Component({
  name: 'Projects',

  components: {
    'v-project-card': () => import('./components/ProjectCard.vue'),
    'v-dialog-add-project': () => import('./components/DialogAddProject.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})
export default class ProjectsComponents extends Vue {
  @NSProjects.State((state: ProjectsState) => state.projects.data)
  private projects!: Project[]

  @NSProjects.State((state: ProjectsState) => state.projects.meta)
  private meta!: Meta

  @NSProjects.State('lastPage') private projectsLastPage!: number | null

  @NSProjects.Action(ProjectsActions.A_GET_PROJECTS)
  private fetchProjects!: (params?: RequestParams) => void

  private visibleDialogAddProject = false

  private statusOptions: ISelect[] = [
    {
      label: 'Активен',
      value: '1',
    },
    {
      label: 'Не активен',
      value: '0',
    },
  ]

  private params: RequestParams = {
    search: '',
    isActive: '',
  }

  private loading:boolean = true

  protected created() {
    this.changePage()
  }

  @Debounce(1000)
  private handleChangeSearch() {
    const search = this.params.search as string

    if (search.length >= 3 || search.length === 0) {
      this.changePage()
    }
  }

  private changeStatus() {
    this.changePage()
  }

  private async changePage(page = 1) {
    this.loading = true
    await this.fetchProjects({ page, ...this.params })
    this.loading = false
    this.$scrollToTop()
  }
}
